import React from 'react'
import { navigate } from 'gatsby'

const Footer: React.FC<{}> = ({}) => {
  function handleClickPrivacyLinkFooter() {
    if (typeof window !== 'undefined' && window.location.pathname !== '/privacy-policy') {
      navigate('/privacy-policy')
    } else {
      let element = document.getElementById('privacy-content-content')
      element ? element.scrollIntoView({ behavior: 'smooth' }) : ''
    }
  }

  return (
    <div className="bg-blue-1 pt-14 pb-[200px] lg:pb-[160px]">
      <div className="max-w-[1100px] mx-auto flex flex-col items-center">
        <img className="w-[204px] h-[62px] lg:w-[143px] lg:h-[43px]" src="/images/home/footer_logo.svg" alt="logo" />
        <div className="flex gap-x-5 mt-10">
          {/* <a href="https://www.twitter.com" target="_blank">
            <img src="/images/home/ic_twitter.svg" width={30} height={30} alt="twitter" />
          </a> */}
          <a href="https://www.linkedin.com/company/tacklehr" target="_blank">
            <img src="/images/home/ic_linkedin.svg" width={30} height={30} alt="linkedin" />
          </a>
          <a href="https://www.facebook.com/TackleHR" target="_blank">
            <img src="/images/home/ic_facebook.svg" width={30} height={30} alt="facebook" />
          </a>
        </div>
        <p className="text-white-1 text-sm leading-4 mt-8">{`© ${new Date().getFullYear()} — TackleHR. All rights reserved`}</p>
        <p className="text-white-1 text-sm leading-4">
          Read our {` `}
          <span
            onClick={() => handleClickPrivacyLinkFooter()}
            className="text-white-1 text-[14px] underline hover:underline hover:brightness-75 cursor-pointer"
          >{`Privacy Policy`}</span>
        </p>
      </div>
    </div>
  )
}

export default Footer
