import React from 'react'
import tw from 'twin.macro'

const PrivacyPolicyContent: React.FC<{}> = ({}) => {
  return (
    <div className="bg-white pt-[184px]" id="privacy-content-content">
      <div className="max-w-[1100px] mx-auto">
        <hr className="border-gray-1 opacity-[0.39] w-[1100px] pb-[38px]"></hr>
        <div className="policy-content">
          <h1 className="policy-title font-heldaneDisplay text-[70px] leading-[84px] text-blue-1 lg:px-4">
            PRIVACY NOTICE
          </h1>
          <div className="policy-text pt-5 pb-10 lg:px-4 w-[872px] lg:w-full">
            {/* Summary */}
            <>
              <PolicyTextStyle>
                Thank you for choosing to be part of our community at TackleHR, LLC, ("TackleHR", "we", "us", "our"). We
                are committed to protecting your personal information and your right to privacy. If you have any
                questions or concerns about this privacy notice, or our practices with regards to your personal
                information, please contact us at{' '}
                <PolicyLinkInlineStyle href="mailto:contact@tacklehr.com" target="_blank">
                  contact@tacklehr.com.
                </PolicyLinkInlineStyle>
              </PolicyTextStyle>
              <PolicyTextStyle>
                When you visit our website{' '}
                <PolicyLinkInlineStyle href="https://www.tacklehr.com" target="_blank">
                  https://www.tacklehr.com
                </PolicyLinkInlineStyle>{' '}
                (the "Website"), and more generally, use any of our services (the "Services", which include the
                Website), we appreciate that you are trusting us with your personal information. We take your privacy
                very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what
                information we collect, how we use it and what rights you have in relation to it. We hope you take some
                time to read through it carefully, as it is important. If there are any terms in this privacy notice
                that you do not agree with, please discontinue use of our Services immediately.
              </PolicyTextStyle>
              <PolicyTextStyle>
                This privacy notice applies to all information collected through our Services (which, as described
                above, includes our Website), as well as, any related services, sales, marketing or events.
              </PolicyTextStyle>
              <PolicyTextStyle>
                Please read this privacy notice carefully as it will help you understand what we do with the information
                that we collect.
              </PolicyTextStyle>
            </>

            {/* Privacy Policy */}
            <>
              <PolicyStrongStyle>Privacy Policy </PolicyStrongStyle>
              <PolicyTextStyle>
                Protecting your private information is our priority. This Statement of Privacy applies to {` `}
                <PolicyLinkInlineStyle href="https://www.tacklehr.com" target="_blank">
                  www.tacklehr.com
                </PolicyLinkInlineStyle>
                , and TackleHR, inc. and governs data collection and usage. For the purposes of this Privacy Policy,
                unless otherwise noted, all references to TackleHR include www.tacklehr.com and TackleHR. The TackleHR
                website is a news and information site. By using the TackleHR website, you consent to the data practices
                described in this statement.
              </PolicyTextStyle>
            </>

            {/* Collection of your Personal Information  */}
            <>
              <PolicyStrongStyle>Collection of your Personal Information </PolicyStrongStyle>
              <PolicyTextStyle>
                We do not collect any personal information about you unless you voluntarily provide it to us. However,
                you may be required to provide certain personal information to us when you elect to use certain products
                or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest
                sponsored by us or one of our partners; (c) signing up for special offers from selected third parties;
                (d) sending us an email message; (e) submitting your credit card or other payment information when
                ordering and purchasing products and services. To wit, we will use your information for, but not limited
                to, communicating with you in relation to services and/or products you have requested from us. We also
                may gather additional personal or non-personal information in the future.{' '}
              </PolicyTextStyle>
            </>

            {/* Sharing Information with Third Parties  */}
            <>
              <PolicyStrongStyle>Sharing Information with Third Parties </PolicyStrongStyle>
              <PolicyTextStyle>
                TackleHR does not sell, rent or lease its customer lists to third parties.{' '}
              </PolicyTextStyle>
              <PolicyTextStyle>
                TackleHR may share data with trusted partners to help perform statistical analysis, send you email or
                postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited
                from using your personal information except to provide these services to TackleHR, and they are required
                to maintain the confidentiality of your information.{' '}
              </PolicyTextStyle>
              <PolicyTextStyle>
                TackleHR may disclose your personal information, without notice, if required to do so by law or in the
                good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with
                legal process served on TackleHR or the site; (b) protect and defend the rights or property of TackleHR;
                and/or (c) act under exigent circumstances to protect the personal safety of users of TackleHR, or the
                public.{' '}
              </PolicyTextStyle>
            </>

            {/* Tracking User Behavior  */}
            <>
              <PolicyStrongStyle>Tracking User Behavior</PolicyStrongStyle>
              <PolicyTextStyle>
                TackleHR may keep track of the websites and pages our users visit within TackleHR, in order to determine
                what TackleHR services are the most popular. This data is used to deliver customized content and
                advertising within TackleHR to customers whose behavior indicates that they are interested in a
                particular subject area.{' '}
              </PolicyTextStyle>
            </>

            {/* Automatically Collected Information */}
            <>
              <PolicyStrongStyle>Automatically Collected Information</PolicyStrongStyle>
              <PolicyTextStyle>
                Information about your computer hardware and software may be automatically collected by TackleHR. This
                information can include: your IP address, browser type, domain names, access times and referring website
                addresses. This information is used for the operation of the service, to maintain quality of the
                service, and to provide general statistics regarding use of the TackleHR website.{' '}
              </PolicyTextStyle>
            </>

            {/* Use of Cookies  */}
            <>
              <PolicyStrongStyle>Use of Cookies</PolicyStrongStyle>
              <PolicyTextStyle>
                The TackleHR website may use "cookies" to help you personalize your online experience. A cookie is a
                text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs
                or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web
                server in the domain that issued the cookie to you.
              </PolicyTextStyle>
              <PolicyTextStyle>
                One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose
                of a cookie is to tell the Web server that you have returned to a specific page. For example, if you
                personalize TackleHR pages, or register with TackleHR site or services, a cookie helps TackleHR to
                recall your specific information on subsequent visits. This simplifies the process of recording your
                personal information, such as billing addresses, shipping addresses, and so on. When you return to the
                same TackleHR website, the information you previously provided can be retrieved, so you can easily use
                the TackleHR features that you customized.
              </PolicyTextStyle>
              <PolicyTextStyle>
                You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but
                you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline
                cookies, you may not be able to fully experience the interactive features of the TackleHR services or
                websites you visit.
              </PolicyTextStyle>
            </>

            {/* Links */}
            <>
              <PolicyStrongStyle>Links</PolicyStrongStyle>
              <PolicyTextStyle>
                This website contains links to other sites. Please be aware that we are not responsible for the content
                or privacy practices of such other sites. We encourage our users to be aware when they leave our site
                and to read the privacy statements of any other site that collects personally identifiable information.{' '}
              </PolicyTextStyle>
            </>

            {/* Security of your Personal Information  */}
            <>
              <PolicyStrongStyle>Security of your Personal Information </PolicyStrongStyle>
              <PolicyTextStyleNoPadding>
                TackleHR secures your personal information from unauthorized access, use, or disclosure.
              </PolicyTextStyleNoPadding>
              <PolicyTextStyleNoPadding>TackleHR uses the following methods for this purpose:</PolicyTextStyleNoPadding>
              <ul className="pb-6">
                <UnorderedListStyle>SSL Protocol</UnorderedListStyle>
              </ul>
              <PolicyTextStyle>
                When personal information (such as a credit card number) is transmitted to other websites, it is
                protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.{' '}
              </PolicyTextStyle>
              <PolicyTextStyle>
                We strive to take appropriate security measures to protect against unauthorized access to or alteration
                of your personal information. Unfortunately, no data transmission over the Internet or any wireless
                network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal
                information, you acknowledge that: (a) there are security and privacy limitations inherent to the
                Internet which are beyond our control; and (b) security, integrity, and privacy of any and all
                information and data exchanged between you and us through this Site cannot be guaranteed.{' '}
              </PolicyTextStyle>
            </>

            {/* Right to Deletion  */}
            <>
              <PolicyStrongStyle>Right to Deletion</PolicyStrongStyle>
              <PolicyTextStyleNoPadding>
                Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
              </PolicyTextStyleNoPadding>
              <ul className="pb-6">
                <UnorderedListStyle>Delete your personal information from our records; and </UnorderedListStyle>
                <UnorderedListStyle>
                  Direct any service providers to delete your personal information from their records.{' '}
                </UnorderedListStyle>
              </ul>
              <PolicyTextStyleNoPadding>
                Please note that we may not be able to comply with requests to delete your personal information if it is
                necessary to:
              </PolicyTextStyleNoPadding>
              <ul className="pb-6">
                <UnorderedListStyle>
                  Complete the transaction for which the personal information was collected, fulfill the terms of a
                  written warranty or product recall conducted in accordance with federal law, provide a good or service
                  requested by you, or reasonably anticipated within the context of our ongoing business relationship
                  with you, or otherwise perform a contract between you and us;
                </UnorderedListStyle>
                <UnorderedListStyle>
                  Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or
                  prosecute those responsible for that activity;
                </UnorderedListStyle>
                <UnorderedListStyle>
                  Debug to identify and repair errors that impair existing intended functionality;
                </UnorderedListStyle>
                <UnorderedListStyle>
                  Exercise free speech, ensure the right of another consumer to exercise his or her right of free
                  speech, or exercise another right provided for by law;
                </UnorderedListStyle>
                <UnorderedListStyle>
                  Comply with the California Electronic Communications Privacy Act;
                </UnorderedListStyle>
                <UnorderedListStyle>
                  Engage in public or peer-reviewed scientific, historical, or statistical research in the public
                  interest that adheres to all other applicable ethics and privacy laws, when our deletion of the
                  information is likely to render impossible or seriously impair the achievement of such research,
                  provided we have obtained your informed consent;
                </UnorderedListStyle>
                <UnorderedListStyle>
                  Enable solely internal uses that are reasonably aligned with your expectations based on your
                  relationship with us;
                </UnorderedListStyle>
                <UnorderedListStyle>Comply with an existing legal obligation; or</UnorderedListStyle>
                <UnorderedListStyle>
                  Otherwise use your personal information, internally, in a lawful manner that is compatible with the
                  context in which you provided the information.
                </UnorderedListStyle>
              </ul>
            </>

            {/* Children Under Thirteen */}
            <>
              <PolicyStrongStyle>Children Under Thirteen</PolicyStrongStyle>
              <PolicyTextStyle>
                TackleHR does not knowingly collect personally identifiable information from children under the age of
                thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to
                use this website.
              </PolicyTextStyle>
            </>

            {/* E-mail Communications */}
            <>
              <PolicyStrongStyle>E-mail Communications</PolicyStrongStyle>
              <PolicyTextStyle>
                From time to time, TackleHR may contact you via email for the purpose of providing announcements,
                promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to
                improve our Services, we may receive a notification when you open an email from TackleHR or click on a
                link therein.
              </PolicyTextStyle>
              <PolicyTextStyle>
                If you would like to stop receiving marketing or promotional communications via email from TackleHR, you
                may opt out of such communications by clicking on the UNSUBSCRIBE botton.
              </PolicyTextStyle>
            </>

            {/* External Data Storage Sites */}
            <>
              <PolicyStrongStyle>External Data Storage Sites</PolicyStrongStyle>
              <PolicyTextStyle>
                We may store your data on servers provided by third party hosting vendors with whom we have contracted.
              </PolicyTextStyle>
            </>

            {/* Changes to this Statement */}
            <>
              <PolicyStrongStyle>Changes to this Statement</PolicyStrongStyle>
              <PolicyTextStyle>
                TackleHR reserves the right to change this Privacy Policy from time to time. We will notify you about
                significant changes in the way we treat personal information by sending a notice to the primary email
                address specified in your account, by placing a prominent notice on our website, and/or by updating any
                privacy information. Your continued use of the website and/or Services available after such
                modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement
                to abide and be bound by that Policy.
              </PolicyTextStyle>
            </>

            {/* Contact Information */}
            <>
              <PolicyStrongStyle>Contact Information</PolicyStrongStyle>
              <PolicyTextStyle>
                TackleHR welcomes your questions or comments regarding this Statement of Privacy. If you believe that
                TackleHR has not adhered to this Statement, please contact TackleHR at:
              </PolicyTextStyle>
              <PolicyTextStyle>
                TackleHR, LLC.
                <br></br>
                11108 North 128th Place
                <br></br>
                Scottsdale, Arizona 85259
              </PolicyTextStyle>
            </>
          </div>
        </div>
      </div>
    </div>
  )
}

const PolicyTextStyle = tw.p`text-[17px] text-blue-1 pb-6 leading-[23px]`
const PolicyTextStyleNoPadding = tw.p`text-[17px] text-blue-1 leading-[23px]`
const PolicyLinkInlineStyle = tw.a`text-[17px] text-blue-1 leading-[23px] underline hover:underline hover:brightness-75`
const PolicyStrongStyle = tw.p`text-[17px] text-blue-1 pb-6 leading-[23px] font-bold`
const UnorderedListStyle = tw.li`list-disc ml-8 leading-[23px] text-[17px] text-blue-1`

export default PrivacyPolicyContent
