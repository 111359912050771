import React from 'react'
import { RouteComponentProps } from '@reach/router'
import Layout from '@src/components/Layout'
import PrivacyPolicy from '@src/components/PrivacyPolicy'

const PrivacyPolicyPage: React.FC<RouteComponentProps<any>> = ({}) => {
  return (
    <Layout>
      <PrivacyPolicy />
    </Layout>
  )
}

export default PrivacyPolicyPage

export const Head = () => (
  <>
    <title>TackleHR</title>
    <meta name="description" content="Your Partner in HR" />
    <meta name="image" content="/images/favicon.png" />
    <link rel="icon" href="/images/favicon.png" type="image/png" sizes="84x60"></link>
  </>
)
