import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
// @ts-ignore: Unreachable code error
import tw, { styled } from 'twin.macro'
import { navigate } from 'gatsby'

interface HeaderProps {
  className?: string
  bgColor?: string
}

const Header: React.FC<HeaderProps> = ({ className = '', bgColor = '' }) => {
  const [isDefaultMenu, setIsDefaultMenu] = useState(true)
  function handleClickHeaderLogo() {
    if (typeof window !== 'undefined' && window.location.pathname !== '/') {
      navigate('/')
    } else {
      let element = document.getElementById('landing-page')
      element ? element.scrollIntoView({ behavior: 'smooth' }) : ''
    }
  }

  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0)

  useEffect(() => {
    const updateWindowDimensions = () => {
      if (typeof window !== 'undefined') {
        const newWidth = window.innerWidth
        setWidth(newWidth)
      }
    }
    typeof window !== 'undefined' && window.addEventListener('resize', updateWindowDimensions)
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', updateWindowDimensions)
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.pathname === '/policies-finder/') {
      if (width > 640) {
        setIsDefaultMenu(true)
      } else {
        setIsDefaultMenu(false)
      }
    } else {
      setIsDefaultMenu(true)
    }
  }, [width])

  return (
    <div
      className={`${
        isDefaultMenu ? 'h-[110px] lg:h-[105px]' : 'h-[90px] lg:h-[85px]'
      }  fixed w-screen z-50 pt-8 lg:pt-4 lg:shadow-2  lg:top-0 ${bgColor ? bgColor : 'bg-white-1'}`}
    >
      <div className="max-w-[1100px] mx-auto flex lg:flex-col">
        <div className={`${isDefaultMenu ? `flex flex-1 items-center lg:justify-center` : `relative px-[27px]`} `}>
          <img
            onClick={() => handleClickHeaderLogo()}
            className="w-[167px] h-[50px] lg:w-[128px] lg:h-[39px] cursor-pointer"
            src="/images/home/logo.svg"
            alt="logo"
          />
          <div className={`${isDefaultMenu ? `flex flex-row relative top-2 lg:top-1` : `hidden`} `}>
            <div className="w-[20px] h-[23px] border-r-[1px] border-gray-1 mr-5"></div>
            <div className="airmason-logo">
              <a href="https://airmason.com/" target="_blank">
                <img src="/images/home/ic_airmason.svg" alt="airmason-logo" />
              </a>
            </div>
          </div>
        </div>
        <div
          className={`flex flex-1 items-center justify-end gap-x-11 lg:gap-x-6 pt-4 lg:justify-center ${
            isDefaultMenu ? '' : 'hidden'
          }`}
        >
          <StyledLink to="/#about">About</StyledLink>
          <StyledLink to="/#services">Services</StyledLink>
          <StyledLink to="/#testimonials">Testimonials</StyledLink>
          <StyledLink to="/#pricing">Pricing</StyledLink>
          {/* Gatsby: Reminder: use <Link> only for internal links! */}
          {/* Mentioned in this document: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#reminder-use-link-only-for-internal-links */}
          <ExternalLinkStyled href="http://blog.tacklehr.com/" target="_blank">
            Blog
          </ExternalLinkStyled>
        </div>
      </div>
    </div>
  )
}

const StyledLink = styled(Link)(() => [tw`text-blue-1 text-lg tracking-normal leading-5 lg:text-sm`])
const ExternalLinkStyled = tw.a`text-blue-1 text-lg tracking-normal leading-5 lg:text-sm`

export default Header
