import React from 'react'
import { RouteComponentProps } from '@reach/router'
import Header from '../Landing/Header'
import Footer from '../Landing/Footer'
import ContactBanner from '../Landing/ContactBanner'
import PrivacyPolicyContent from './PolicyContent'

const PrivacyPolicy: React.FC<RouteComponentProps<any>> = ({}) => {
  return (
    <div>
      <div className="w-screen flex flex-col bg-white mx-auto">
        <Header />
        <PrivacyPolicyContent />
        <Footer />
      </div>
      <ContactBanner />
    </div>
  )
}

export default PrivacyPolicy
